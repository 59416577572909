import React from 'react';

const NotFound = () => {
    return ( 
        <React.Fragment>
            <h1>404 Page Not Found</h1>
            <p>The page you were looking for was not found </p>
        </React.Fragment>
     );
}
 
export default NotFound;